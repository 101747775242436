import React from 'react'
import { graphql } from 'gatsby'

import {
  SEO,
  Layout,
  ProjectTitleBlock,
  MainImageBlock,
  DescriptionBlock,
  ImageBlock,
  SpecsBlock,
  LocationMapBlock,
  Divider,
} from 'Components'

export const projectQuery = graphql`
  query($slug: String!) {
    project: contentfulProject(slug: { eq: $slug }) {
      slug
      name
      mainImage {
        title
        description
        fluid(maxWidth: 2400, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      specs {
        place
        program
        client
        team
        startOfConstruction(formatString: "MMMM Do, YYYY")
        programType
        surface
        author
        endOfConstruction(formatString: "MMMM Do, YYYY")
        location {
          lon
          lat
        }
      }
      seo {
        id
        title
        description
        image {
          title
          description
          fixed(width: 1200, height: 630) {
            width
            height
            src
          }
        }
      }
      imageBlock1 {
        title
        description
        fluid(maxWidth: 2400, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      imageBlock2 {
        title
        description
        fluid(maxWidth: 2400, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      descriptionBlock1 {
        descriptionBlock1
      }
      descriptionBlock2 {
        descriptionBlock2
      }
    }
  }
`

const Project = ({ data }) => {
  const { project } = data

  const {
    slug,
    seo,
    name,
    mainImage,
    imageBlock1,
    imageBlock2,
    descriptionBlock1: { descriptionBlock1 },
    descriptionBlock2: { descriptionBlock2 },
    specs,
    specs: { location },
  } = project

  return (
    <>
      <SEO pathname={`/${slug}`} title={seo.title} description={seo.description} image={seo.image.fixed.src} />
      <Layout>
        <ProjectTitleBlock name={name} programType={specs.programType} />
        <MainImageBlock title={mainImage.title} description={mainImage.description} fluid={mainImage.fluid} />
        <DescriptionBlock description={descriptionBlock1} />

        <ImageBlock block={imageBlock1} />

        <DescriptionBlock description={descriptionBlock2} />

        <ImageBlock block={imageBlock2} />

        <Divider />

        <SpecsBlock specs={specs} />

        {location && <LocationMapBlock location={location} />}
      </Layout>
    </>
  )
}

export default Project
